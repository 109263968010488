import { gsap, ScrollToPlugin, ScrollTrigger } from "gsap/all"
import Swiper from "swiper"
import "what-input"
import objectFitImages from "object-fit-images"

import { Scroller } from "./libs/Scroller"
import { GlobalHeader } from "./libs/GlobalHeader"
import { HeadingAnim } from "./libs/HeadingAnim"
import { CompanyHistory } from "./libs/CompanyHistory"
import { Home } from "./libs/Home"
import { Tab } from "./libs/Tab"
import { Accordion } from "./libs/Accordion"
import { Post } from "./libs/Post"
import { TopHero } from "./libs/TopHero"

import "simplebar"

/** gsapのプラグイン登録 */
gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

/** scrollerインスタンス */
const scroller = new Scroller()

$(() => {
  /** スムーススクロール */
  Scroller.bindClickScrollTo(".js-scroll-to")
  /** URLにハッシュがついているとき */
  Scroller.initializeTo()

  /** タブ */
  const tab = new Tab()

  /** アコーディオン */
  const accordion = new Accordion()

  /** タブが変更された */
  tab.on("changeTab", () => {
    accordion.destroyAndSetup()
  })

  /** object-fit polyfill */
  objectFitImages()

  /** モーダル */
  // Ajaxタイプ
  $(".js-modal-inline").magnificPopup({
    type: "inline"
  })

  /** グローバルヘッダ */
  const globalHeader = new GlobalHeader({ denseClassName: ".-dense" })

  /** スクロールイベント */
  scroller.on("scroll", (params) => {
    globalHeader.dense(params.scrollTop)
  })
  globalHeader.dense()

  /** カルーセル */
  $(".swiper-container").each((idx, el) => {
    new Swiper(el, {
      // CSSでサイズ調整しているからauto
      slidesPerView: "auto",
      // スライド間のマージン
      spaceBetween: 20,
      // スワイプ時に慣性スクロール
      freeMode: true,
      // スライドに合わせてスクロールがストップする
      freeModeSticky: true,
      // スワイプ後の遅延
      touchRatio: 0.03,
      // ナビゲーション
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      // レスポンシブ対応（画面幅による設定）
      breakpoints: {
        // 画面幅が 751px 以上の場合
        751: {
          spaceBetween: 62
        }
      }
    })
  })

  /** 見出しのアニメーション(トップページ以外) */
  if ($("#page-home").length === 0) {
    new HeadingAnim()
  } else {
    /** トップページ */
    new Home()
  }

  /** 伊藤園のあゆみのページ (/company/history/) */
  if ($("#company_history").length) {
    new CompanyHistory()
  }

  /** スマホ下層メニュー */
  $(".js-sp-submenu").each((idx, me) => {
    const $me = $(me)
    const $head = $me.find(".js-sp-submenu-head")
    const $body = $me.find(".js-sp-submenu-body")
    let isShow = false
    $head.on("click", () => {
      if (isShow) {
        gsap.to($body, {
          height: 0,
          onStart: () => {
            isShow = false
            $me.removeClass("-show")
          }
        })
      } else {
        gsap.to($body, {
          height: "auto",
          onStart: () => {
            isShow = true
            $me.addClass("-show")
          },
          onComplete: () => {
            $body.css({ height: "auto" })
          }
        })
      }
    })
  })

  /** アコーディオン */
  // レスポンシブ対応のためリサイズしたらリセットする
  $(window)
    .on(
      "resize",
      $.throttle(100, () => {
        accordion.destroyAndSetup()
      })
    )
    .trigger("resize")

  /** 投稿記事 */
  if ($("#wp-post").length) {
    new Post()
  }

  /** トップページ */
  if ($("#top_hero").length) {
    new TopHero()
  }
})
