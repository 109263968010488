/** ニュース記事 */
export class Post {
  constructor() {
    this.$post = $("#wp-post")
    this.table()
  }

  /** テーブル */
  table() {
    this.$post.find("table").each((idx, table) => {
      const $table = $(table)
      $table.wrap("<div class='p-post__table'></div>")
    })
  }
}
