import mq from "./MediaQuery"

export class TopHero {
  constructor() {
    this.$globalHeader = $("#global_header")
    this.$el = $("#top_hero")
    this.$img = this.$el.find(".p-topHero__image")
    this.$yt = this.$el.find(".p-topHero__yt")
    this.image = this.$img.find("img")[0]

    this.isPlay = false

    /** メディアクエリ変更 */
    mq.on("onChange", ({ size }) => {
      this.size = size
    })
    mq.checkBreakPoint()

    this.resizeHero()

    $(window).on("resize.cover", () => this.resizeCover())

    this.resizeCover()

    // YoutubeAPIを埋め込み
    const script = document.createElement("script")
    script.src = "//www.youtube.com/iframe_api"
    const firstScript = document.getElementsByTagName("script")[0]
    firstScript.parentNode.insertBefore(script, firstScript)

    this.youtube

    const ytId = $("#yt_player").data("youtube")

    window.onYouTubeIframeAPIReady = () => {
      this.youtube = new window.YT.Player("yt_player", {
        width: "560",
        height: "315",
        videoId: ytId,
        playerVars: {
          autoplay: 1,
          controls: 0,
          loop: 1,
          playlist: ytId,
          playsinline: 1
        },
        events: {
          onReady: () => {
            this.ytOnReady()
          },
          onStateChange: (event) => {
            this.ytStateChange(event)
          }
        }
      })
    }
  }

  ytOnReady() {
    this.resizeYoutube()
    $(window).on("resize.yt", () => this.resizeYoutube())
    this.youtube.mute()
    this.youtube.playVideo()
    $(window).off("resize.cover")
  }

  ytStateChange(event) {
    if (event.data == window.YT.PlayerState.PLAYING && !this.isPlay) {
      this.isPlay = true
      this.$img.fadeOut(500)
    }
  }

  resizeHero() {
    if (this.size === "sp") {
      this.$el.height(window.innerHeight - this.$globalHeader.height())
    }
  }

  // 画像リサイズ
  resizeCover() {
    const width = this.$el.width()
    const height = this.$el.height()

    const imgRatio = this.image.naturalWidth / this.image.naturalHeight
    const ratio = this.$el.width() / this.$el.height()

    let targetWidth

    if (width > height) {
      // 横長
      targetWidth = this.$el.height() * ratio
    } else {
      // 縦長
      targetWidth = this.image.naturalHeight * imgRatio
    }

    this.$img.css({ width: `${targetWidth}px`, height: `100%` })
  }

  // Youtubeリサイズ
  resizeYoutube() {
    const width = this.$el.width()
    const height = this.$el.height()
    const ratio = 16 / 9

    if (width > height) {
      // 横長
      const targetHeight = this.$el.width() * ratio
      this.$yt.css({ width: `100%`, height: `${targetHeight}px` })
    } else {
      // 縦長
      const targetWidth = this.$el.height() * ratio
      this.$yt.css({ width: `${targetWidth}px`, height: `100%` })
    }
  }
}
