export class Accordion {
  constructor() {}

  setup() {
    $(".js-accordion").accordion({
      header: ".js-accordion-head",
      collapsible: true,
      active: false
    })
  }

  destroy() {
    $(".js-accordion").accordion("destroy")
  }

  destroyAndSetup() {
    if ($(".js-accordion").hasClass("ui-accordion")) {
      this.destroy()
    }
    window.requestAnimationFrame(() => {
      this.setup()
    })
  }
}
