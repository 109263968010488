import gsap, { Power4 } from "gsap"
import mq from "./MediaQuery"
export class GlobalHeader {
  constructor(params) {
    this.$header = $("#global_header")
    this.params = { ...params, denseClassName: "-dense" }
    this.isSp = false

    /** メディアクエリ変更 */
    mq.on("onChange", ({ size }) => {
      if (size === "sp") {
        /** SP */
        this.isSp = true
        // メガメニューのSPイベントを設定
        this.bindMegaMenuSp()
        // メガメニューのPCイベントを削除
        this.removeMegaMenuPc()
        // サーチエリアのSPイベントを設定
        this.bindSearchAreaSp()
      } else {
        /** PC・タブレット */
        this.isSp = false
        // メガメニューのSPイベントを削除
        this.removeMegaMenuSp()
        // メガメニューのPCイベントを設定
        this.bindMegaMenuPc()
        // サーチエリアのPCイベントを設定
        this.bindSearchAreaPc()
      }
    })
    mq.checkBreakPoint()

    // ナビのカレント表示
    this.navCurrent()
  }

  /** ナビのカレント表示 */
  navCurrent() {
    const pathnameArr = location.pathname.split("/")
    if (pathnameArr.length <= 2) return
    const key = pathnameArr[1]
    if (!key.length) return
    const $target = $(`#nav_${key}`)
    if (!$target.length) return
    // aria-current="true"をつける
    $target.attr("aria-current", true)
  }

  /** メガメニューのSP版イベントを設定 */
  bindMegaMenuSp() {
    // メニューボタンをクリック
    let isOpen = false
    $(".p-header__menuButton").on("click", (e) => {
      if (!this.isSp) return
      const $me = $(e.currentTarget)
      const $screen = $me.find(".p-header__menuButton__screen")
      if (isOpen) {
        $("#nav-group").removeClass("-open")
        $screen.text("メニューを開く")
        $me.attr("aria-expanded", false)
      } else {
        $("#nav-group").addClass("-open")
        $screen.text("メニューを閉じる")
        $me.attr("aria-expanded", true)
      }
      isOpen = !isOpen
    })

    // 子メニューの開閉
    $(".p-header__primaryNavButton").each((idx, me) => {
      const $button = $(me)
      const $menu = $(me).next()
      let isOpenMenu = false
      $button.on("click", () => {
        if (isOpenMenu) {
          gsap.to($menu, {
            height: 0,
            ease: Power4.easeOut,
            duration: 0.8
          })
        } else {
          gsap.to($menu, {
            height: "auto",
            ease: Power4.easeOut,
            duration: 0.8,
            onComplete: () => {
              $menu.css({ height: "auto" })
            }
          })
        }
        isOpenMenu = !isOpenMenu
      })
    })
  }

  /** メガメニューのSP版イベントを削除 */
  removeMegaMenuSp() {
    $("#nav-group").removeClass("-open")
    $(".p-header__menuButton").off("click")
    $(".p-header__primaryNavButton").off("click")

    $(".p-header__primaryNavButton").each((idx, me) => {
      const $button = $(me)
      const $menu = $(me).next()
      $button.off("click")
      $menu.css({ height: 0 })
    })
  }

  /** メガメニューのPC版イベントをバインドする */
  bindMegaMenuPc() {
    /** メガメニュー */
    $(".js-global-menu").each((idx, me) => {
      const $nav = $(me)
      const $btn = $nav.find(".p-header__primaryNavButton")
      if ($btn.length > 0) {
        const $menu = $nav.find(".p-headerChildMenu")

        $nav.on("mouseenter", () => {
          $btn.attr("aria-expanded", true)
          $menu.attr("aria-hidden", false)
          $menu.addClass("-active")
          gsap.to($menu, {
            height: "auto",
            ease: Power4.easeOut,
            duration: 0.8,
            onComplete: () => {
              $menu.css({ height: "auto" })
            }
          })
        })

        $nav.on("mouseleave", () => {
          $menu.removeClass("-active")
          $btn.attr("aria-expanded", false)
          $menu.attr("aria-hidden", true)
          gsap.to($menu, {
            height: 0,
            ease: Power4.easeOut,
            duration: 0.8
          })
        })
      }
    })
  }

  /** メガメニューのPC版イベントを消去する */
  removeMegaMenuPc() {
    $(".js-global-menu").each((idx, me) => {
      const $nav = $(me)
      const $menu = $nav.find(".p-headerChildMenu")
      const $btn = $nav.find(".p-header__primaryNavButton")

      // PCのイベントを外す
      $nav.off("mouseenter")
      $nav.off("mouseleave")

      $menu.removeClass("-active")
      $btn.attr("aria-expanded", false)
      $menu.attr("aria-hidden", true)
      $menu.css({ height: 0 })
    })
  }

  /** 検索ボタンのPC版イベントをバインドする */
  bindSearchAreaPc() {
    $(document).off("click.searchArea")
    $("#header-search").css({ height: 0 })
    $(".p-header__searchArea")
      .on("mouseenter", () => {
        gsap.to("#header-search", {
          height: "auto",
          ease: Power4.easeOut,
          duration: 0.3,
          onComplete: () => {
            $("#input_search").focus()
            $("#header-search").css({ height: "auto" })
          }
        })
      })
      .on("mouseleave", () => {
        gsap.to("#header-search", {
          height: 0,
          ease: Power4.easeOut,
          duration: 0.3
        })
      })
  }

  /** 検索ボタンのSP版イベントをバインドする */
  bindSearchAreaSp() {
    $(".p-header__searchArea").off("mouseenter")
    $(".p-header__searchArea").off("mouseleave")
    $("#header-search").css({ height: 0 })

    let isOpen = false

    // 開く
    $(".p-header__searchArea").on("click", () => {
      if (isOpen) return
      gsap.to("#header-search", {
        height: "auto",
        ease: Power4.easeOut,
        onComplete: () => {
          isOpen = !isOpen
          if (isOpen) {
            $("#input_search").focus()
          }
        }
      })
    })

    // 閉じる
    $(document).on("click.searchArea", (e) => {
      if (!$(e.target).closest("#header-search").length && isOpen) {
        gsap.to("#header-search", {
          height: 0,
          ease: Power4.easeOut,
          onComplete: () => {
            isOpen = !isOpen
          }
        })
      }
    })
  }

  /** 三点リーダーのイベントをバインドする */
  bindSubMenuEvent() {
    if (this.isSp) return
    $("#submenu_body").css({ height: 0 })
    $("#btn_submenu")
      .on("mouseenter", () => {
        gsap.to("#submenu_body", {
          height: "auto",
          ease: Power4.easeOut,
          duration: 0.3,
          onComplete: () => {
            $("#submenu_body").css({ height: "auto" })
          }
        })
      })
      .on("mouseleave", () => {
        gsap.to("#submenu_body", {
          height: 0,
          ease: Power4.easeOut,
          duration: 0.3
        })
      })
  }

  /** 三点リーダーのイベントを外す */
  removeSubMenuEvent() {
    $("#btn_submenu").off("mouseenter")
    $("#btn_submenu").off("mouseleave")
    $("#submenu_body").css({ height: "auto" })
  }

  /**
   * スクロールするとヘッダメニューにCSSクラスを付与する
   */
  dense(_scrollTop) {
    const scrollTop =
      _scrollTop === undefined ? $(window).scrollTop() : _scrollTop
    if (scrollTop > 0 && !this.isDense) {
      this.isDense = true
      this.$header.addClass(this.params.denseClassName)
      this.bindSubMenuEvent()
      return
    }

    if (scrollTop <= 0 && this.isDense) {
      this.isDense = false
      this.$header.removeClass(this.params.denseClassName)
      this.removeSubMenuEvent()
    }
  }
}
